import React from "react";

const ContactTextArea = ({ row, placeholder, name, defaultValue } : any) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          className="dark:bg-gray-300 border-[f0f0f0] w-full resize-none rounded border py-3 px-[14px] text-base dark:text-black text-body-color outline-none focus:border-primary focus-visible:shadow-none"
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
};

export default ContactTextArea;
