import { Button, TextField, Typography } from "@mui/material";
import React, { useState, SyntheticEvent } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useStore from "../hook/store";
import { toast } from "react-toastify";
import auth from "../services/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const { t } = useTranslation();
    const [emailorPhone, setEmailorPhone] = useState("")
    const [password, setPassword] = useState("")
    const { setUser } = useStore()
    const navigate = useNavigate()

    const onSubmit = async (e: SyntheticEvent) => {
        e.preventDefault()
        const wait = toast.loading("Please wait....", {
            theme: "colored",
            type: "info",
            closeButton: true,
        })

        await auth.login(emailorPhone, password).then(async (res) => {
            await auth.getUser().then((res) => {
                setUser(res.data);
                toast.update(wait, {
                    render: "Loggin Successfull.",
                    type: "success",
                    theme: "colored",
                    autoClose: 2500,
                    isLoading: false,
                    closeOnClick: true,
                    hideProgressBar: false,
                })
                navigate('/')
            }).catch((err) => {
                if (err?.code === "ERR_NETWORK")
                    toast.update(wait, {
                        render: "Connection Error",
                        theme: "colored",
                        autoClose: 2500,
                        type: "error",
                        isLoading: false,
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                else
                    toast.update(wait, {
                        render: err?.response?.data,
                        theme: "colored",
                        autoClose: 2500,
                        type: "error",
                        isLoading: false,
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
            })
        }).catch(() => {
            toast.update(wait, {
                render: "Incorrect Credentials",
                theme: "colored",
                autoClose: 2500,
                type: "error",
                isLoading: false,
                closeOnClick: true,
                hideProgressBar: false,
            })
        })
    }

    return (
        <div className="bg-cyan50 dark:bg-gray-700">
            <Helmet>
                <title>{t("Login")}</title>
            </Helmet>

            <div className="lg:px-28 px-3 text-center py-5">
                <div className="shadow lg:m-28 dark:bg-gray-900 rounded">
                    <Typography variant="h5" fontWeight={"bold"} className="p-4">{t("Login")}</Typography>
                    <form className="grid gap-5 m-16 pb-10" onSubmit={(onSubmit)}>
                        <div className="lg:px-36">
                            <TextField onChange={(e) => setEmailorPhone(e.target.value)} required placeholder={t("userName")} className="dark:bg-white" fullWidth size="small" />
                        </div>
                        <div className="lg:px-36">
                            <TextField onChange={(e) => setPassword(e.target.value)} required placeholder={t("password")} className="dark:bg-white" fullWidth type="password" size="small" />
                        </div>
                        <div className="lg:px-36">
                            <Button variant="contained" type="submit" size="small" fullWidth>{t("Login")}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
