import React from "react";

const ContactInputBox = ({ type, placeholder, name }: any) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          className="dark:bg-gray-300 border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base dark:text-black text-body-color outline-none focus:border-primary focus-visible:shadow-none"
        />
      </div>
    </>
  );
};

export default ContactInputBox;
