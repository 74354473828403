import React from "react";
import Header from "../components/Header";
import Fotter from "../components/Fotter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({ children }: any) => {
  return (
    <div className="h-screen dark:bg-slate-800 dark:text-white">
      <Header />
      <ToastContainer limit={5} />
      {children}
      <Fotter />
    </div>
  );
};

export default Layout;
