import { Carousel } from "flowbite-react";
import React from "react";

interface Image {
  src: string;
  alt: string;
}

const Slides = () => {
  const images: Array<Image> = [
    {
      src: require("../assets/images/namrud-grid-white.jpg"),
      alt: "first",
    },
    {
      src: require("../assets/images/2.jpg"),
      alt: "second",
    },
    {
      src: require("../assets/images/1.jpg"),
      alt: "third",
    },
  ];

  return (
    <Carousel slideInterval={3000} pauseOnHover className="rounded-xl">
      {images.map((image) => (
        <img alt={image.alt} src={image.src} />
      ))}
    </Carousel>
  );
};

export default Slides;
