import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Slides from "../components/Slides";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import { GiPowerGenerator } from "react-icons/gi";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Slides2 from "../components/Slides2";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-cyan50 dark:bg-gray-700">
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <div className="h-64 xl:h-screen">
        <Slides />
      </div>
      <span className="text-5xl underline flex flex-col items-center py-5">
        {t("News")}
      </span>
      <div className="grid lg:grid-cols-2 px-10 gap-4">
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/namrud-grid-white.jpg")}
            alt=""
            className="lg:h-48 lg:w-56"
          />
          <div className="">
            <h6 className="font-bold text-lg text-center underline">Big Discount</h6>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              From today upto next 3 consecutive months when come with another new customer you'll get 15% off.
            </p>
          </div>
        </div>
        <div className="lg:flex gap-3">
          <img
            src={require("../assets/images/namrud-grid-white.jpg")}
            alt=""
            className="lg:h-48 lg:w-56"
          />
          <div className="">
            <h6 className="font-bold text-lg text-center underline">We're Opening New Branch @Bole</h6>
            <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
              Our new branch will be open on next month. Now we can reach you at your place.
            </p>
          </div>
        </div>
      </div>
      <span className="text-5xl underline flex flex-col items-center py-5">
        {t("OurServices")}
      </span>
      <div className="grid lg:grid-cols-3 px-10 gap-4">
        <div className="flex flex-col items-center gap-3">
          <CarRepairIcon sx={{ fontSize: 75 }} />
          <div className="">
            <h6 className="font-bold text-lg text-center">{t("Maintenance")}</h6>
            <p className="indent-5 text-center">
              Our Maintenance team at Namrud Engineering PLC is dedicated to delivering unparalleled service and
              repair solutions, characterized by prompt responses and available 24/7 support.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <GiPowerGenerator size={"75"} />
          <div className="">
            <h6 className="font-bold text-lg text-center">{t("GRepair")}</h6>
            <p className="indent-5 text-center">
              At Namrud Engineering PLC, we provide a wide array of innovative and cost-effective solutions ranging
              from routine servicing to extensive overhauls.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <AddIcCallIcon sx={{ fontSize: 75 }} />
          <div className="">
            <h6 className="font-bold text-lg text-center">{t("OnCallService")}</h6>
            <p className="indent-5 text-center">
              At Namrud Engineering PLC, our on-call service operates round the clock, 24 hours a day, bolstered by a
              dedicated team of expert service technicians and technical managers.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <EngineeringIcon sx={{ fontSize: 75 }} />
          <div className="">
            <h6 className="font-bold text-lg text-center">{t("ConsAndComm")}</h6>
            <p className="indent-5 text-center">
              Namrud Engineering PLC specializes in comprehensive Electro-Mechanical solutions, catering to
              residential, industrial, and commercial sectors.
            </p>
          </div>
        </div>
      </div>
      <span className="text-5xl underline flex flex-col items-center py-5">
        Our Partners
      </span>
      <div className="relative m-auto w-full overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*10)]">
          <Slides2 />
          <Slides2 />
        </div>
      </div>
    </div>
  );
};

export default Home;
