import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-cyan50 dark:bg-gray-700">
      <Helmet>
        <title>{t("About")}</title>
      </Helmet>

      <div className="xl:grid xl:grid-cols-2">
        <div className="text-center lg:px-10 px-3 py-5 flex">
          <img
            src={require("../assets/images/namrud-grid-white.jpg")}
            alt="namrud"
            className="mx-auto"
          />
        </div>
        <div className="xl:px-28 px-3 text-center py-5">
          <span className="text-3xl underline">{t("OurHistory")}</span>
          <p className="text-left text-justify py-3">
            Namrud Engineering PLC has been a prominent player in the electromechanical services sector
            since its inception in 2010. Founded by four dedicated Electro mechanical Engineers, our company has
            been instrumental in addressing a wide range of challenges within our nation. Over the years, Namrud
            Engineering has honed its expertise in various services such as Generator installation, comprehensive
            strap down repairs, and general maintenance. We have particularly excelled in high-powered generator
            installations and both preventive and corrective maintenance services, showcasing our commitment to
            specialized solutions.
          </p>
          <p className="text-left text-justify py-3">
            At Namrud Engineering, our success is deeply rooted in our personalized approach to client
            relationships. We handle each project with a sense of ownership and dedication, ensuring that every
            task is executed with meticulous care. Our team of professionals is renowned for their professionalism,
            analytical prowess, and adept problem-solving skills, which enables us to deliver exceptional results
            consistently.
          </p>
          <p className="text-left text-justify py-3">
            Moreover, Namrud Engineering PLC places utmost importance on safety and environmental protection
            principles. We adhere strictly to industry standards and regulations, prioritizing the well-being of our
            workforce, clients, and the environment alike. Our unwavering commitment to excellence and
            sustainability underscores every aspect of our operations, reinforcing our position as a trusted leader in
            the Electromechanical services industry.
          </p>
          <span className="text-3xl underline">{t("mission")}</span>
          <p className="text-left text-justify py-3">
            <List>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <ListItemText>
                  To consistently deliver high-quality engineering solutions that contribute to the socio-economic
                  development of our country and beyond.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <ListItemText>
                  To actively promote and advance technological knowledge through continuous learning,
                  research, and collaboration with industry leaders and academic institutions.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <ListItemText>
                  To establish and maintain a state-of-the-art research and development facility dedicated to
                  pioneering new technologies and enhancing our service offerings.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <ListItemText>
                  To uphold the highest standards of ethics, professionalism, and environmental sustainability in all
                  our operations.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
                <ListItemText>
                  To empower our employees through training, career development opportunities, and a supportive
                  work environment, fostering innovation and excellence.
                </ListItemText>
              </ListItem>
            </List>
            These mission statements align with the vision of becoming a leading engineering firm in East Africa
            while emphasizing the importance of technological innovation, knowledge advancement, and sustainable
            development.
          </p>
          <span className="text-3xl underline">{t("vision")}</span>
          <p className="text-left text-justify">
            To become a leading engineering firm in East Africa, driving national development through innovative
            solutions and technological advancement, while fostering a culture of knowledge preservation and
            growth.
          </p>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 lg:px-32 gap-4">
        <div className="text-center">
          <img
            src={require("../assets/images/gen1.jpeg")}
            alt="Prof"
            className="mx-auto"
          />
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/gen2.jpeg")}
            alt="Prof"
            className="mx-auto"
          />
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/gen3.jpeg")}
            alt="Prof"
            className="mx-auto"
          />
        </div>
        <div className="text-center">
          <img
            src={require("../assets/images/gen4.jpeg")}
            alt="Prof"
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
