import { create } from "zustand";
import { persist } from "zustand/middleware";
import { user } from "../common/type";

interface States {
  user: user | null;
}

interface Actions {
  setUser: (type: any) => void;
  reset: () => void;
}

const initialStore: States = {
  user: null,
};

const useStore = create(
  persist<States & Actions>(
    (set) => ({
      ...initialStore,

      setUser: (user) => {
        set((state) => ({
          ...state,
          user,
        }));
      },
      reset: () => set(initialStore),
    }),
    {
      name: "neccessaries",
      getStorage: () => sessionStorage,
    }
  )
);

export default useStore;
