import { Button, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useStore from "../hook/store";

const News = () => {
    const { t } = useTranslation()
    const { user } = useStore()
    return (
        <div>
            <Helmet>
                <title>{t("News")}</title>
            </Helmet>

            <div className="lg:px-28 px-3 dark:bg-gray-700 py-5">
                <p className="text-3xl text-center underline">{t("News")}</p>
                {user && (<Button variant="contained">{t("AddNews")}</Button>)}
                <p className="py-3 shadow-2xl">
                    <div className="text-left text-lg text-justify m-5 lg:flex gap-3">
                        <img
                            src={require("../assets/images/2.jpg")}
                            alt="News"
                            className="lg:h-48 lg:w-56"
                        />
                        <p className="text-left text-lg text-justify">
                            <Typography align="center" variant="h6" fontWeight="bold">15% off</Typography>
                            From today upto next 3 consecutive months when come with another new customer you'll get 15% off.
                        </p>
                    </div>
                    <div className="text-small px-5 sm:grid grid-cols-2">
                        <p>
                            <span className="underline font-bold">Published On</span>: 2024-09-09
                        </p>
                        <p className="lg:text-right">
                            <span className="underline font-bold">Updated On</span>: 2024-09-09
                        </p>
                    </div>
                </p>
                <p className="py-3 shadow-2xl">
                    <div className="text-left text-lg text-justify m-5 lg:flex gap-3">
                        <img
                            src={require("../assets/images/2.jpg")}
                            alt="News"
                            className="lg:h-48 lg:w-56"
                        />
                        <p className="text-left text-lg text-justify">
                            <Typography align="center" variant="h6" fontWeight="bold">We're Opening New Branch @Bole</Typography>
                            Our new branch will be open on next month. Now we can reach you at your place.
                        </p>
                    </div>
                    <div className="text-small px-5 sm:grid grid-cols-2">
                        <p>
                            <span className="underline font-bold">Published On</span>: 2024-08-19
                        </p>
                        <p className="lg:text-right">
                            <span className="underline font-bold">Updated On</span>: 2024-10-01
                        </p>
                    </div>
                </p>
            </div>
        </div>
    );
};

export default News;
