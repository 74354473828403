import React from "react";

interface Image {
  src: string;
  alt: string;
}

const Slides = () => {
  const images: Array<Image> = [
    {
      src: require("../assets/images/01.jpeg"),
      alt: "first",
    },
    {
      src: require("../assets/images/02.jpeg"),
      alt: "second",
    },
    {
      src: require("../assets/images/03.jpeg"),
      alt: "third",
    },
    {
      src: require("../assets/images/04.jpeg"),
      alt: "fourth",
    }
    ,
    {
      src: require("../assets/images/05.jpeg"),
      alt: "fifth",
    }
    ,
    {
      src: require("../assets/images/06.jpeg"),
      alt: "sixth",
    }
    ,
    {
      src: require("../assets/images/07.jpeg"),
      alt: "seventh",
    }
    ,
    {
      src: require("../assets/images/08.jpeg"),
      alt: "eighth",
    }
    ,
    {
      src: require("../assets/images/09.jpeg"),
      alt: "ninth",
    }
    ,
    {
      src: require("../assets/images/10.jpeg"),
      alt: "tenth",
    }
    ,
    {
      src: require("../assets/images/11.jpeg"),
      alt: "eleventh",
    }
    ,
    {
      src: require("../assets/images/12.jpeg"),
      alt: "twelvth",
    }
    ,
    {
      src: require("../assets/images/13.jpeg"),
      alt: "thirteenth",
    }
    ,
    {
      src: require("../assets/images/14.jpeg"),
      alt: "fourteenth",
    }
    ,
    {
      src: require("../assets/images/15.jpeg"),
      alt: "fifteenth",
    }
    ,
    {
      src: require("../assets/images/16.jpeg"),
      alt: "sixteenth",
    }
  ];

  return (
    // <Carousel slideInterval={1500} pauseOnHover className="rounded-xl">
    <>
      {images.map((image) => (
        <img alt={image.alt} src={image.src} width={80} height={80} className="slide flex w-[125px] items-center justify-center" />
      ))}
    </>

    // </Carousel>
  );
};

export default Slides;
