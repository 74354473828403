import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Home: "Home",
      Namrud: "Namrud",
      NamrudLogo: "Namrud Logo",
      About: "About",
      Contact: "Contact",
      AllRightResereved: "All Rights Reserved.",
      DeveloperContact: "Developer Contact",
      ContactUs: "Contact Us",
      GetInTouch: "Get In Touch With Us",
      OurLocation: "Our Location",
      PhoneNumber: "Phone Number",
      EmailAddress: "Email Address",
      Name: "Your Name",
      Email: "Your Email",
      Phone: "Your Phone",
      Message: "Your Message",
      SendMessage: "Send Message",
      ClothesShop: "Traditional Clothes Selling Shop",
      PageNotFound: "Page Not Found",
      ooops: "Ooops ...",
      PageNotFoundMessage: "Sorry, This page doesn't exist!",
      OurServices: "Our Services",
      OurHistory: "History of the company",
      News: "News",
      Maintenance: "Car Maintenance",
      GRepair: "Gen-Set Repair",
      OnCallService: "On Call Service",
      ConsAndComm: "Consultancy and Commissioning",
      Login: "Login",
      AddNews: "Add News",
      userName: "User Name",
      password: "Password",
      mission: "Mission",
      vision: "Vision",
      services: "Services",
      workingHours: "Working Hours",
      lt: "LT",
      gt: "GT",
      hi: "Hi",
    },
  },
  am: {
    translation: {
      hi: "ሰላም",
      lt: "በኛ",
      gt: "በውጭ",
      workingHours: "የስራ ሰአት",
      services: "አገልግሎታችን",
      userName: "የተጠቃሚ ስም",
      mission: "ተልዕኮ",
      vision: "ራዕይ",
      password: "የይለፍ ቃል",
      AddNews: "ዜና ያስገቡ",
      Login: "ይግቡ",
      ConsAndComm: "የባለሞያ ምክር",
      OnCallService: "የስልክ አገልግሎት",
      GRepair: "የጀነሬተር ጥገና",
      Maintenance: "ጥገና",
      News: "ዜናዎች",
      OurHistory: "የድርጅታችን ታሪክ",
      OurServices: "አገልግሎታችን",
      PageNotFound: "ገፁ አልተገኘም",
      ooops: "ውይ ...",
      PageNotFoundMessage: "ይቅርታ ፤ ገፁ አልተገኘም",
      Home: "መነሻ ገፅ",
      Namrud: "ናምሩድ",
      NamrudLogo: "የናምሩድ መለያ",
      About: "ስለ",
      Contact: "ያግኙን",
      AllRightResereved: "መብቱ በሕግ የተጠበቀ።",
      DeveloperContact: "የአበልፃጊው መገኛ",
      ContactUs: "ያግኙን",
      GetInTouch: "ከእኛ ጋር ይገናኙ",
      OurLocation: "አድራሻችን",
      PhoneNumber: "ስልክ ቁጥር",
      EmailAddress: "የፈጣን መልዕክት አድራሻ",
      Name: "ስም",
      Email: "ፈጣን መልዕክት",
      Phone: "ስልክ",
      Message: "መልዕክት",
      ClothesShop: "ባህላዊ ልብሶች መሽጫ መደብር",
      SendMessage: "ይላኩ",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
