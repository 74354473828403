import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
// import useStore from "../hook/store";
// import auth from "../services/auth";

const Header = () => {
  const [theme, setTheme] = useState("");
  const [lang, setLang] = useState("en");
  const { t, i18n } = useTranslation();
  // const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2)
  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3)
  const handleClick3 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
  const open4 = Boolean(anchorEl4)
  const handleClick4 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  // const { user, reset } = useStore()
  // const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
  // const open5 = Boolean(anchorEl5)
  // const handleClick5 = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl5(event.currentTarget);
  // };
  // const handleClose5 = () => {
  //   setAnchorEl5(null);
  // };

  return (
    <Navbar fluid rounded className="bg-cyan100 dark:bg-gray-900">
      <Navbar.Brand>
        <Link to="/">
          <img
            alt={t("NamrudLogo")}
            className="mr-3 h-6 sm:h-9 rounded-full inline"
            src={require("../assets/images/namrud-grid-white.jpg")}
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            {t("Namrud")}
          </span>
        </Link>
      </Navbar.Brand>
      <div className="flex md:order-last">
        <Navbar.Toggle className="mr-2" />
        <button
          onClick={() => {
            setTheme(theme === "dark" ? "light" : "dark");
          }}
          className="mr-4"
        >
          {theme === "dark" ? (
            <svg
              className="h-8 w-8 text-black dark:text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="5" />{" "}
              <line x1="12" y1="1" x2="12" y2="3" />{" "}
              <line x1="12" y1="21" x2="12" y2="23" />{" "}
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />{" "}
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />{" "}
              <line x1="1" y1="12" x2="3" y2="12" />{" "}
              <line x1="21" y1="12" x2="23" y2="12" />{" "}
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />{" "}
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
            </svg>
          ) : (
            <svg
              className="h-8 w-8 text-black dark:text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          )}
        </button>
        <button
          onClick={() => {
            setLang(lang === "en" ? "am" : "en");
          }}
          className="info mr-2"
        >
          {lang === "en" ? <>አማርኛ</> : <>English</>}
        </button>
        {/* {user
          ?
          <div className="flex flex-row items-center">
            <span onClick={handleClick5}>{t("hi")} {user.userName.slice(0, 5)}</span>
            <Menu open={open5} onClose={handleClose5} anchorEl={anchorEl5}>
              <MenuItem onClick={async () => {
                reset()
                await auth.logout()
                handleClose5()
                navigate('/')
              }}>
                {t("Logout")}
              </MenuItem>
            </Menu>
          </div>
          :
          <button onClick={() => navigate('login')}>
            {t("Login")}
          </button>
        } */}
      </div>
      <Navbar.Collapse>
        <Navbar.Link>
          <Link to="/">{t("Home")}</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/about">{t("About")}</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/contact">{t("Contact")}</Link>
        </Navbar.Link>
        <Navbar.Link>
          <button onClick={handleClick}>{t("services")}</button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClick2}>Electro-Mechanical</MenuItem>
            <MenuItem onClick={handleClick3}>Generator</MenuItem>
            <MenuItem onClick={handleClick4}>Automotive</MenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem onClick={handleClose2}>Machinery Installation</MenuItem>
            <MenuItem onClick={handleClose2}>Machinery Maintainance</MenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl3}
            open={open3}
            onClose={handleClose3}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem onClick={handleClose3}>Maintainance</MenuItem>
            <MenuItem onClick={handleClose3}>Services</MenuItem>
            <MenuItem onClick={handleClose3}>Commisions & Consultency</MenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl4}
            open={open4}
            onClose={handleClose4}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem onClick={handleClose4}>Car Services</MenuItem>
            <MenuItem onClick={handleClose4}>Electrical & Mechanical Works</MenuItem>
            <MenuItem onClick={handleClose4}>Mechanical Shops</MenuItem>
          </Menu>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/news">{t("News")}</Link>
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
